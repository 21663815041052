* {
	box-sizing: border-box;
}

html {
	font-size: 10px;
	width: 100%;
	height: 100%;
}

body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

#root {
	display: flex;
	justify-content: center;
}

@font-face {
	font-family: 'sofia-pro';
	src: local('sofia-pro'), url(styles/fonts/sofiapro-regular.eot) format('eot');
	src: local('sofia-pro'), url(styles/fonts/sofiapro-regular.eot#iefix) format('embedded-opentype'),
	local('sofia-pro'), url(styles/fonts/sofiapro-regular.woff2) format('woff2'), local('sofia-pro'),
	url(styles/fonts/sofiapro-regular.woff) format('woff'), local('sofia-pro'),
	url(styles/fonts/sofiapro-regular.ttf) format('truetype'), local('sofia-pro'),
	url(styles/fonts/sofiapro-regular.svg) format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'sofia-pro';
	src: local('sofia-pro'), url(styles/fonts/sofiapro-light.eot) format('eot');
	src: local('sofia-pro'), url(styles/fonts/sofiapro-light.eot#iefix) format('embedded-opentype'),
	local('sofia-pro'), url(styles/fonts/sofiapro-light.woff2) format('woff2'), local('sofia-pro'),
	url(styles/fonts/sofiapro-light.woff) format('woff'), local('sofia-pro'),
	url(styles/fonts/sofiapro-light.ttf) format('truetype'), local('sofia-pro'),
	url(styles/fonts/sofiapro-light.svg) format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'sofia-pro';
	src: local('sofia-pro'), url(styles/fonts/sofiapro-semibold.eot) format('eot');
	src: local('sofia-pro'), url(styles/fonts/sofiapro-semibold.eot#iefix) format('embedded-opentype'),
	local('sofia-pro'), url(styles/fonts/sofiapro-semibold.woff2) format('woff2'), local('sofia-pro'),
	url(styles/fonts/sofiapro-semibold.woff) format('woff'), local('sofia-pro'),
	url(styles/fonts/sofiapro-semibold.ttf) format('truetype'), local('sofia-pro'),
	url(styles/fonts/sofiapro-semibold.svg) format('svg');
	font-weight: 600;
	font-style: normal;
}
