@dropdown-hover-bg-color: #c5c7c8;
@active-language-bg-color: #ffffff;
@dropdown-bg-color: #f8f8f8;
@dropdown-border-color: #c5c7c8;
@language-text-color: #2f3640;

.main {
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &:hover .active-language-box {
    background: @dropdown-bg-color;
    border: 1px solid @dropdown-border-color;
    border-bottom: none;
    z-index: 30;
  }

  &:hover .dropdown-button {
    background: @dropdown-hover-bg-color;
    transform: rotate(180deg);
  }

  &:hover .dropdown-box {
    opacity: 1;
    display: block;
  }
}

.active-language-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 0.8rem 1.2rem 1.2rem;
  border: 1px solid transparent;
  border-bottom: none;
  background: @active-language-bg-color;
  height: 48px;
  max-height: 48px;
}

.dropdown-button {
  margin-left: 4px;
  height: 23px;
  width: 23px;
  border-radius: 50%;
  font-size: 5px;
  line-height: 23px;
  text-align: center;
  -webkit-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
  transform-origin: 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-box {
  background-color: @dropdown-bg-color;
  border: 1px solid @dropdown-border-color;
  font-size: 16px;
  display: none;
  opacity: 0;
  padding: 9px 20px 20px;
  position: absolute;
  right: 0;
  -webkit-transition: opacity .3s ease-out;
  transition: opacity .3s ease-out;
  width: 165px;
  z-index: 20;
  top: 47px;
}

.language-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.7rem;

  &:hover .flag {
    opacity: 1;
  }
}

.language-text {
  font-weight: 500;
  color: @language-text-color;
}

.flag {
  width: 22px;
  opacity: 0.5;
  -webkit-transition: opacity .3s ease-out;
  transition: opacity .3s ease-out;

  &-active {
    opacity: 1;
  }
}
@primary-color: #333333;