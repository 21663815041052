.expand-box {
  padding: 1rem 2rem;
  border: #ccc 1px solid;
  background-color: #f3f3f3;
  border-radius: 4px;
}

.expand-box-text {
  line-height: 1.6;
}

.button-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  cursor: pointer;
}

.expanded-children {
  height: 6rem;
  overflow: hidden;
  position: relative;
  color: #b5b5b5;

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(243,243,243);
    background: linear-gradient(0deg, rgba(243,243,243,1) 0%, rgba(243,243,243,1) 20%, rgba(243,243,243,0.2) 100%);
    width: 100%;
    height: 100%;
  }
}

.expanded-children-displayed {
  height: auto;
  color: #2b3130;

  &:after {
    display: none;
  }
}

.expand-button {
  margin-right: 1rem;

  &-clicked {
    transform: rotate(180deg);
  }
}
@primary-color: #333333;