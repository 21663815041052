@import 'styles/variables';

@field-note-color: #999999;
@info-icon-color: #999999;

.font-bold {
	font-weight: 600;
}

.header-box {
	display: flex;
	padding: 0 @app-box-padding-horizontal;
	min-height: 8rem;
	border-bottom: 1px solid #b0b0b0;
}

.header-left-box,
.header-right-box {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 50%;
	height: 100%;
}

.header-right-box {
	justify-content: flex-end;
}

.header-right-box .locale {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	border-left: #ccc 1px solid;
	cursor: pointer;
}

.header-right-box .locale:first-child {
	border-left: 0;
}

.header-right-box .locale.current {
	cursor: text;
	font-weight: bold;
}

.logo {
	width: 168px;
	height: 41px;
}

.footer-box {
	display: flex;
	border-top: 1px solid #b0b0b0;
	padding: 3rem @app-box-padding-horizontal;
	margin-top: 4rem;
	flex-wrap: wrap;
	justify-content: center;

	& > .footer-link:not(:last-child) {
		margin-right: 2rem;
	}
}

.footer-link {
	color: #666;
	text-decoration: none;
}

.footer-link:hover {
	color: #a81326;
}

.content-box {
	background-color: white;
	padding: 3rem @app-box-padding-horizontal;
}

.content-title {
	font-size: 2.4rem;
	font-weight: 700;
	line-height: 1.1;
	margin-bottom: 2.4rem;
}

.note-link {
	text-decoration: underline;
}

.post-info {
	display: flex;
	flex-direction: column;
	margin-left: 11rem;
	margin-bottom: 1rem;

	@media screen and (max-width: 420px) {
		margin-left: 1rem;
	}
}

.phone-info {
	display: flex;
	flex-direction: column;
	margin-left: 1rem;

	@media screen and (max-width: 420px) {
		margin-left: 1rem;
	}
}

.point3 {
	display: flex;
	flex-direction: column;
}

.point4 {
	display: flex;

	@media screen and (max-width: 420px) {
		flex-direction: column;
	}
}

.by-phone, .by-post {
	width: 10rem;
}

.by-phone {
	flex-shrink: 0;
}

.field-box {
	display: flex;
	flex-direction: column;
	margin-top: 4rem;

	&-contained {
		margin-top: 2rem;
	}
}

.field-label {
	font-weight: 600;
	line-height: 1.6;
}

.field-label.required:after {
	margin-left: 2px;
	color: #d9534f;
	content: '*';
}

.field-label-box {
	margin-bottom: 0.7rem;
}

.field-label-note {
	color: @field-note-color;
	font-size: 1.2rem;
	margin-bottom: 0.7rem;
	margin-left: 0.5rem;
}

.field-note {
	font-size: 1.35rem;
	font-weight: 300;
	margin-top: 0.7rem;
	margin-bottom: 0.4rem;
	color: @field-note-color;
}

.field-row {
	display: flex;
	align-items: flex-start;
	width: 100%;

	@media screen and (max-width: 500px) {
		flex-direction: column;
	}

	& > .field-box {
		width: 50%;

		@media screen and (max-width: 500px) {
			width: 100%;
		}
	}

	& > .field-box:first-child {
		padding-right: 1rem;

		@media screen and (max-width: 500px) {
			padding-right: 0;
		}
	}

	& > .field-box:last-child {
		padding-left: 1rem;

		@media screen and (max-width: 500px) {
			padding-left: 0;
		}
	}
}

.violation-category-option {
	display: flex;
	align-items: center;
}

.violation-category-info {
	cursor: pointer;
	margin-left: 0.8rem;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media screen and (max-width: 610px) {
		margin-left: auto;
	}
}

.violation-category-info-icon-box {
	padding: 0 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.violation-category-info-icon {
	transform: scale(0.8);
	fill: @info-icon-color;
}

.violation-category-note {
	margin-top: 1.6rem;
	font-size: 1.3rem;
}

.personal-info {
	padding-bottom: 3rem;
}

.validation-error {
	background-color: #fff0f1;
	border: 1px solid #e35b66;
	color: #cc3340;
	border-radius: 4px;
	font-size: 1.2rem;
	line-height: 1.4rem;
	padding: 0.5rem;
	margin-top: 0.2rem;

	&-margin-lg {
		margin-top: 1rem;
	}
}

.radio-option {
	padding-top: 1rem;
}

.radio-option input {
	min-width: 2rem;
}

.radio-option label {
	padding-left: 1rem;
}

.description-textarea {
	height: 15rem;
}

input,
textarea {
	padding: 0.8rem 1.6rem;
	line-height: 1.6;
	font-size: 1.5rem;
	font-family: sofia-pro, sans-serif;
	color: #55595c;
	border: 1px solid #979797;
	border-radius: 4px;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.label-attachment-sub {
	color: #999;
	font-size: 1.2rem;
	margin-bottom: 0.7rem;
}

.upload-dropzone {
	border: 1px solid #ddd;
	font-size: 12px;
	overflow: hidden;
	position: relative;
	text-align: center;
}

.upload-dropzone input[type='file'] {
	opacity: 0;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	height: 100%;
	width: 100%;
}

.upload-dropzone .span-main {
	display: inline-block;
	line-height: 24px;
	padding: 10px;
}

.fake-href {
	color: #a81326;
}

.upload-dropzone .fake-href:before {
	display: inline-block;
	width: 20px;
	height: 20px;
	vertical-align: middle;
	margin: -5px 5px 0 0;
	content: '';
	background-image: url(../../images/attachment.svg);
	background-size: 20px auto;
}

.upload-pool {
	margin: 0;
	overflow: hidden;
	padding: 0;
}

.upload-item {
	font-size: 1.2rem;
	margin-top: 10px;
	padding: 5px;
}

.upload-item .item-name:before {
	display: inline-block;
	width: 12px;
	height: 12px;
	vertical-align: middle;
	margin: -5px 5px 0 0;
	content: '';
	background-image: url(../../images/attachment.svg);
	background-size: 12px auto;
}

.item-remove {
	cursor: pointer;
	color: mediumvioletred;
	margin-left: 1rem;
	font-weight: 600;
}

.submit-box {
	display: flex;
	margin-top: 4rem;
}

button {
	color: #fff !important;
	background-color: #202524;
	border-color: #202524;
	font-size: 1.5rem;
	font-family: sofia-pro, sans-serif;
	padding: 1rem 2.4rem;
	border-radius: 4px;
	border-style: solid;
	cursor: pointer;

	&:disabled {
		cursor: not-allowed;
		background-color: #9da2a0;
		border-color: #9da2a0;
	}
}

.error-message {
	display: flex;
	padding: 2rem;
	border: #ccc 1px solid;
	background-color: #ffc0c0;
	font-weight: 500;
	line-height: 1.6;
	border-radius: 4px;
	margin-top: 2rem;
}

.success-box {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.success-msg-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 2rem;
	border: #ccc 1px solid;
	background-color: #f3f3f3;
	border-radius: 4px;
}

.success-icon {
	display: flex;
	width: 10rem;
}

.success-msg {
	width: 100%;
	text-align: center;
	line-height: 2;
	font-weight: 500;
	margin-top: 2rem;
}

.success-box button {
	margin-top: 5rem;
}

.spinner-box {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner-img {
	width: 5rem;
}

.captcha-box {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 2rem;
}

.captcha {
	margin-left: -1px;
}

@media screen and (max-width: 500px) {
	.header-box,
	.content-box,
	.footer-box {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}

@media screen and (min-width: 501px) and (max-width: 850px) {
	.header-box,
	.content-box,
	.footer-box {
		padding-left: 3rem;
		padding-right: 3rem;
	}
}

@primary-color: #333333;